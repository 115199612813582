import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const LifestyleCreep = () => (
  <Layout>
    <SEO
      title="Lifestyle Creep and How to Avoid it"
      description="It might sound easy, the logic is simple but the truth is that a great deal of us live month to month despite an increase in pay"
    />
    <h1>Lifestyle Creep and How to Avoid it</h1>

    <p>As you progress in your career and life, it is expected that the increase in your income beats inflation at a minimum. In an ideal scenario, you will earn significantly more than you were previously - every few years. I know this isn’t the case for everyone but earning more as you progress isn’t out of the question.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/YCAn9jo.jpg"
        alt="lifestyle creep and how to avoid it"
        description="lifestyle creep single dollar on grey background"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/@giorgiotrovato"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Giorgio Trovato
      </a>
      {" "} on Unsplash
    </p>

    <p>Then there’s our desires. I’m sure most of us would be happy if we could save a good amount of our income for investment, purchasing something big or just to have a safety net. That’s not too much to ask is it?</p>
    <p>It might sound easy, the logic is simple but the truth is that a great deal of us live month to month despite an increase in pay. It seems like just thinking about it isn’t enough to change it, real action needs to happen.</p>

    <h2>Why Does it Happen?</h2>
    <p>One of the main reasons many people run into this problem is through lifestyle creep. As we progress, we generally make friends with other people who are doing well and have a slightly better lifestyle. They might have a bigger house, better car, more expensive clothes, eat at better restaurants and so on. It takes some strong will to see all of this happening around you and not participate even though you have the funds to do so.</p>
    <p>By the way, there is nothing wrong with spending your money, you earned it. It would be kind of strange to go from restraining your habits to get by then earning more only to act the same way. With more money should come the choice to make choices to get better things.</p>
    <p>One of the ideas I would like to explore is where you try to save money in the moment but end up spending more on replacing, repairing and maintaining the things that you’ve bought.</p>
    <p>Picture buying a car with mileage into the hundreds of thousands, 15 years old with a patchy service book. Making a choice like that when you need your car to drive 50 miles a day is a poor choice unless you are a mechanic. After all is said and done, you could have bought a 3 year old car that would have been more reliable and cheaper to maintain.</p>

    <h2>How to Fix it</h2>
    <p>Fixing lifestyle creep isn’t easy and it’s called creep for a reason. It happens gradually and as a result, you don’t even realise what’s going on. The important thing is that you’ve realised it is happening and now you can do something about it.</p>

    <h2>What do You Need</h2>
    <p>Make a list of all the things you need in life. You will come up with a list of necessities you need to survive each day. Don’t worry about living like a forest person, you can add things like the internet, a phone bill and a car if you need to get around. The idea is to be honest with yourself to get down to what you actually need.</p>
    <p>Then you need to write a list of the things that are nice to have and contribute to your quality of life. I’m not sure what you will have in here but they will be more than needs, instead they will be wants.</p>

    <h2>Be Yourself</h2>
    <p>I mentioned earlier how just by being around certain environments causes you to spend more money than you might have planned to. It is said that you are the average of the people you spend the most time with and this rings true in terms of the amount you spend if you don’t make a conscious effort to avoid it.</p>
    <p>If you are going on a holiday with your friends, you have to spend about the same as them on travel and accommodation at least. Then if you are going to any events, the tickets cost the same. There’s the same thing with food and most other things you take part in.</p>
    <p>Back at home, it’s likely that you all earn about the same. There will be some outliers who earn a whole lot more and maybe a whole lot less but for the most part, you will all be similar. So, what happens when Johnny decides to buy a brand new sports saloon? You might just follow him. Then what happens when Timothy decides to splash on a weekend 2-seater. You might just follow him too.</p>
    <p>How can you let your friends have all the fun and you turn up to their houses in your worn clothes, practical car and haircut you did yourself. At some point the envy might start to kick in and you will start spending some of your spare cash on some lifestyle upgrades.</p>

    <h2>Set a Goal</h2>
    <p>You might think this is similar to setting a budget but it isn’t. Your problem might be mental and no matter how hard you try, you just keep spending. One of the best things you can do to combat this is to set a goal to do something with the money.</p>
    <p>A lot of us spend because we don’t actually have a higher purpose for the money. Things like saving for a house deposit sound good and they are enough to motivate most people but when the goal is so far away, it might sound like a dream.</p>
    <p>In this case you can keep the long term goal the same but you need some short term goals to go along with it. Choose an amount you want to put away each month. That way you can move this onto a separate account so you can’t even see it. Spending the money after this will feel the same and you probably won’t even notice the smaller budget.</p>
    <p>Spending tends to slow down when the account is running low so all you are doing is bringing the slowing process forward.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default LifestyleCreep
